<template>
  <v-dialog v-model="dialog" max-width="800px" origin="center center" persistent :fullscreen="isMobile">
    <v-card >
      <v-card-title>{{ isUpdating ? 'Editar' : 'Cadastrar' }} Usuário</v-card-title>
      <v-divider></v-divider>
      <v-card-title>
        <v-icon class="mr-1">
          {{ icons.mdiAccount }}
        </v-icon>
        <small>Dados</small>
      </v-card-title>
      <div v-if="loading" class="d-flex justify-center">
        <v-progress-circular
          indeterminate
          color="secondary"
        ></v-progress-circular>
      </div>
      <v-form v-if="!loading" v-model="formValid" ref="form">
        <v-card-text>
          <div class="row col-xs">
            <div class="col-md-8">
              <v-text-field 
                v-model="userForm.name"
                label="Nome"
                :rules="[rules.required]"
                outlined
                hide-details="auto"
                dense
              />
            </div>
            <div class="col-md-4">
              <v-text-field
                v-model="userForm.cpf"
                label="CPF"
                :rules="[rules.required, rules.cpf]"
                v-mask="'###.###.###-##'"
                outlined
                hide-details="auto"
                dense
              />
            </div>
          </div>
          <div class="row col-xs">
            <div class="col-md-6">
              <v-text-field
                v-model="userForm.email"
                label="email"
                outlined
                hide-details="auto"
                dense
              />
            </div>
            <div class="col-md-6">
              <v-text-field 
                v-model="userForm.phone"
                label="Telefone"
                :rules="[rules.required, rules.phoneNumber]"
                v-mask="'(##) # ####-####'"
                outlined
                hide-details="auto"
                dense
              />
            </div>
          </div>
          <div class="row col-xs">
            <div class="col-md-6">
              <v-text-field
                v-model="userForm.password"
                outlined
                :type="isPasswordVisible ? 'text' : 'password'"
                label="Senha"
                placeholder="Password"
                :append-icon="passwordVisibleIcon"
                hide-details="auto"
                @click:append="isPasswordVisible = !isPasswordVisible"
                dense
              />
            </div>
            <div class="col-md-6">
              <v-text-field
                v-model="userForm.confirmPassword"
                outlined
                :type="isPasswordVisible ? 'text' : 'password'"
                label="Confirmar Senha"
                placeholder="Password"
                :append-icon="passwordVisibleIcon"
                hide-details="auto"
                @click:append="isPasswordVisible = !isPasswordVisible"
                dense
              />
            </div>
          </div>
        </v-card-text>
      </v-form>
      <v-divider></v-divider>
      <v-card-title class="pb-0">
        <v-icon class="mr-1">
          {{ icons.mdiMapMarker }}
        </v-icon>
        <small>Endereço</small>
      </v-card-title>
      <v-card-text class="pa-5 pb-0">
        <div v-if="loading" class="d-flex justify-center">
          <v-progress-circular
            indeterminate
            color="secondary"
          ></v-progress-circular>
        </div>
      </v-card-text>
      <v-card-text>
        <AddressComponent v-if="!loading" ref="address" :initialData="userForm.main_address" @submit="handleAddress" :dense="true"/>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          class="text-capitalize"
          @click="close"
        >
          Cancelar
        </v-btn>
        <v-btn class="text-capitalize" color="success"  @click="submitForm">
          <v-icon>{{ icons.mdiContentSave }}</v-icon>
          Salvar Informações
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AddressComponent from '@/components/app/address/addressComponent'
import { OPTIONS_UF } from '@/constants/option'
import { mdiAccount, mdiEyeOutline, mdiEyeOffOutline, mdiMapSearch, mdiContentSave, mdiMapMarker } from '@mdi/js'
import formMixin from '@/util/mixins/formMixin'

const userForm = {
  name: null,
  email: null,
  cpf: null,
  phone: null,
  password: null,
  confirmPassword: null,
  status: true,

  address: {
    cep: null,
    number: null,
    street: null,
    neighborhood: null,
    complement: null,
    state: null,
    city: null,
  },

}

export default {
  name: 'UserForm',

  components: { AddressComponent },

  mixins: [formMixin],

  data() {
    return {
      uf: OPTIONS_UF,
      dialog: false,
      icons: {
        mdiAccount,
        mdiMapSearch,
        mdiContentSave,
        mdiMapMarker 
      },
      isPasswordVisible: false,
      isUpdating: false,
      formValid: null,
      addressFormValid: null,
      userForm: userForm,
      loading: false,
    }
  },

  computed: {
    passwordVisibleIcon() {
      return this.isPasswordVisible ? mdiEyeOffOutline : mdiEyeOutline
    },
  },

  methods: {
    open(id) {
      this.dialog = true
      if (this.$refs.form) {
        this.$refs.form.reset()
      }
      if (id) {
        this.isUpdating = true
        this.loading = true
        this.$http.$get(`/user/${id}`).then(res => {
          if (res.status == 200) {
            this.userForm = res.data
            this.loading = false
          }
        })
      }
    },

    handleAddress(e) {
      this.userForm.main_address = e
    },

    close(){
      this.dialog = false
    },

    submitForm() {
      let formValid = this.$refs.form.validate()
      let addressValid = this.$refs.address.validate()
      if (formValid && addressValid) {
        if (this.isUpdating) {
          this.$http.$put(`/user/${this.userForm.id}`, this.userForm).then(res => {
            if (res.status == 200) {
              this.dialog = false
              this.$emit('onSubmit')
            }
          })
        } else {
          this.$http.$post('/user', this.userForm).then(res => {
            if (res.status == 200) {
              this.dialog = false
              this.$emit('onSubmit')
            }
          })
        } 
      }
    },    
  },
}
</script>

<style>
</style>
