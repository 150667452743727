<template>
  <div>
    <h1 class="ma-2 ml-0">
      Usuários
    </h1>
    <v-card>
      <v-card-title class="d-flex align-center justify-space-between py-sm-2 py-6">
        <div class="row">
          <v-text-field
            v-model="searchQuery"
            placeholder="Pesquisar"
            outlined hide-details dense
            class="user-search col-sm-6 pa-2"
          >
          </v-text-field>
        </div>
        <div :class="{'w-full py-2':isMobile}">
          <v-btn color="success" class="ma-sm-2 my-2 text-capitalize" :block="isMobile" @click="newUser()">
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Novo Usuário</span>
          </v-btn>
        </div>
      </v-card-title>

      <v-data-table
        :items-per-page="25"
        :page.sync="page"
        item-key="id"
        v-model="selectedOrders"
        :headers="tableColumns"
        :search="searchQuery"
        :items="users"
        :loading="loading"
        loading-text="Carregando Tabela..."
        @page-count="pageCount = $event"
        :footer-props="{
          itemsPerPageOptions: [25,50,100,-1],
          itemsPerPageText: 'Itens por pagina',
          itemsPerPageAllText: 'Todos',
          disablePagination: true,
          nextIcon: '',
          prevIcon: '',
          pageText: ''
        }"
      >
        <!-- name -->
        <template v-slot:item.name="{ item }">
          <div class="d-flex align-center">
            <v-avatar
              :color="item.avatar ? '' : 'primary'"
              :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
              size="32"
            >
              <v-img v-if="item.avatar" :src="require(`@/assets/images/avatars/${item.avatar}`)"></v-img>
              <span v-else class="font-weight-medium">{{ avatarText(item.name) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name: 'users', params: { id: item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.name }}
              </router-link>
              <small>@{{ item.name }}</small>
            </div>
          </div>
        </template>

        <!-- role -->
        <template v-slot:item.roles="{ item }">
          <div class="d-flex align-center">
            <v-avatar
              size="30"
              :color="resolveUserRoleVariant(item.roles[0].name)"
              :class="`v-avatar-light-bg ${resolveUserRoleVariant(item.roles[0].name)}--text me-3`"
            >
              <v-icon size="18" :color="resolveUserRoleVariant(item.roles[0].name)">
                {{ resolveUserRoleIcon(item.roles[0].name) }}
              </v-icon>
            </v-avatar>

            <span class="text-capitalize">{{ item.roles[0].name }}</span>
          </div>
        </template>

        <!-- status -->
        <template v-slot:item.status="{ item }">
          <v-chip
            small
            :color="resolveUserStatusVariant(item.status)"
            :class="`${resolveUserStatusVariant(item.status)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            <div v-if="item.status === 1">Ativo</div>
            <div v-if="item.status === 0">Inativo</div>
          </v-chip>
        </template>

        <!-- actions -->
        <template v-slot:item.actions="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="editUser(item)">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Editar</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item v-if="item.status == 1" link @click="inactiveUser(item)">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Inativar</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-else link @click="activeUser(item)">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiRestore }}
                  </v-icon>
                  <span>Reativar</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:footer class="d-flex">
          <v-pagination
            v-model="page"
            :length="pageCount"
          ></v-pagination>
        </template>
      </v-data-table>
    </v-card>
    <UserForm ref="userForm" @onSubmit="getUsers" />
  </div>
</template>

<script>
import {
  mdiDeleteOutline,
  mdiAccountCheckOutline,
  mdiAccountOutline,
  mdiAccountPlusOutline,
  mdiAccountRemoveOutline,
  mdiCogOutline,
  mdiDatabaseOutline,
  mdiDnsOutline,
  mdiPencilOutline,
  mdiPlus,
  mdiExportVariant,
  mdiDotsVertical,
  mdiFileDocumentOutline,
  mdiHome,
  mdiRestore,
} from '@mdi/js'

import UserForm from '@/components/administration/user/forms/UserForm'

import tableMixin from '@/util/mixins/tableMixin'

export default {
  components: { UserForm },

  mixins: [tableMixin],

  data() {
    return {
      selectedRows: [],
      users: [],
      roles: '',
      searchQuery: null,
      roleFilter: null,
      planFilter: null,
      statusFilter: null,
      totalUserListTable: null,
      loading: false,
      options: {
        sortBy: ['id'],
        sortDesc: [true],
      },
      page: null,
      pageCount: null,


      icons: {
        mdiPlus,
        mdiDeleteOutline,
        mdiAccountCheckOutline,
        mdiAccountOutline,
        mdiAccountPlusOutline,
        mdiAccountRemoveOutline,
        mdiCogOutline,
        mdiDatabaseOutline,
        mdiDnsOutline,
        mdiPencilOutline,
        mdiExportVariant,
        mdiDotsVertical,
        mdiFileDocumentOutline,
        mdiHome,
        mdiRestore
      },

      tableColumns: [
        { text: 'USER', value: 'name' },
        { text: 'EMAIL', value: 'email' },
        { text: 'PERFIL', value: 'roles', sortable: false },
        { text: 'STATUS', value: 'status', sortable: false },
        {
          text: 'ACTIONS',
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ],

      userModal: false,
    }
  },

  created() {
    this.getUsers()
  },

  methods: {
    getUsers() {
      this.loading = true
      this.$http
        .index('/user')
        .then(response => {
          if (response.status == 200) {
            this.users = response.data
            this.totalUserListTable = response.data.total
            this.loading = false
          }
        })
        .catch(error => {
          console.log(error)
        })
    },

    resolveUserRoleVariant(role) {
      if (role === 'accountant') return 'primary'
      if (role === 'admin') return 'warning'
      if (role === 'user') return 'success'

      return 'primary'
    },

    resolveUserRoleIcon(role) {
      if (role === 'accountant') return mdiAccountOutline
      if (role === 'admin') return mdiCogOutline
      if (role === 'user') return mdiDatabaseOutline

      return mdiAccountOutline
    },

    newUser() {
      this.$refs.userForm.open()
    },

    editUser(content) {
      this.$refs.userForm.open(content.id)
    },

    inactiveUser(content){
      this.user = content
      this.user.status = 0
      this.$http.$put('/user/' + content.id, this.user).then( res => {
          console.log(res);
      }).catch(err => {
          console.log(err);
      })
    },

    activeUser(content){
      this.user = content
      this.user.status = 1
      this.$http.$put('/user/' + content.id, this.user).then( res => {
          console.log(res);
      }).catch(err => {
          console.log(err);
      })
    },
  },
}
</script>

<style>
</style>
